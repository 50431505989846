import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';

type ImgHtmlProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export interface Props extends ImgHtmlProps {
  src: string;
}

const sizes = [300, 600, 1000];

export const BlurUpImage = ({ src, ...props }: Props) => {
  const [sizeI, setSizeI] = useState(0);
  const ref = useRef<HTMLImageElement>(null);
  const isMaxSize = sizeI === sizes.length - 1;

  useEffect(() => setSizeI(0), [src]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const loaded = () => {
      if (!isMaxSize) {
        setSizeI(sizeI + 1);
      }
    };

    ref.current.addEventListener('load', loaded);

    const current = ref.current;
    return () => current?.removeEventListener('load', loaded);
  }, [isMaxSize, sizeI]);

  return (
    <img alt="" {...props} ref={ref} src={`${src}&size=${sizes[sizeI]}`} />
  );
};
