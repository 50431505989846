import { TokenApiService } from './TokenApiService';
import { Injector } from 'react-service-injector';

export interface CalendarEvent {
  date?: string;
  rawDate: string;
  time: string;
  description: string;
  who: string;
}

export interface Birthday {
  date: string;
  name: string;
}

export class EventsService {
  private readonly api: TokenApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(TokenApiService);
  }

  public getFutureEvents(): Promise<CalendarEvent[]> {
    return this.api.jsonGet('/events/calendar/future');
  }

  public getFutureBirthdays(): Promise<Birthday[]> {
    return this.api.jsonGet('/events/birthdays/future');
  }
}
