import { BlurUpImage } from '../components/BlurUpImage';
import { Gallery, GalleryService, Photo } from '../services/GalleryService';
import { useService } from 'react-service-injector';
import { useEffect, useState } from 'react';
import { randomElement, randomIntBetween } from '../utils/random';
import {
  Birthday,
  CalendarEvent,
  EventsService,
} from '../services/EventsService';
import { formatDateDayMonth } from '../utils/date';

interface ImageWithGallery {
  image: Photo;
  gallery: Gallery;
}

export const GalleryPage = () => {
  const galleryService = useService(GalleryService);
  const eventsService = useService(EventsService);

  const [current, setCurrent] = useState<ImageWithGallery>();
  const [sloppy, setSloppy] = useState(0);
  const [eventsAndBirthdays, setEventsAndBirthdays] = useState<
    (CalendarEvent | Birthday)[]
  >([]);

  useEffect(() => {
    const reload = () => {
      galleryService
        .getGalleries()
        .then((galleries) => {
          const allImages = galleries.flatMap((g) =>
            g.photos.map(
              (i): ImageWithGallery => ({
                gallery: g,
                image: i,
              }),
            ),
          );

          if (allImages.length === 0) {
            setCurrent(undefined);
            return;
          }

          setCurrent(randomElement(allImages));
          setSloppy(randomIntBetween(-3, 3));
        })
        .catch((e) => console.error(`Could not load galleries: ${e.message}`));

      Promise.all([
        eventsService.getFutureEvents(),
        eventsService.getFutureBirthdays(),
      ]).then(([events, birthdays]) =>
        setEventsAndBirthdays(
          combineEventsAndBirthdays(events, birthdays).slice(0, 5),
        ),
      );
    };
    reload();
    const timer = setInterval(reload, 10000);

    return () => clearInterval(timer);
  }, [eventsService, galleryService]);

  if (!current) {
    return null;
  }

  return (
    <div className="gallery-page">
      <div className="image-container">
        <h1>
          {current.gallery.title} | {current.image.title}
        </h1>
        <div className="is-flex-grow-1">
          <BlurUpImage
            src={current.image.publicUrl}
            style={{
              transform: `rotate(${sloppy}deg)`,
            }}
          />
        </div>
      </div>
      <div className="events">
        {eventsAndBirthdays.map((e, i) => (
          <div key={i} className="event">
            {isCalendarEvent(e) ? (
              <>
                <div className="title">
                  {e.date ? formatDateDayMonth(e.date) : e.rawDate} -{' '}
                  {e.description}
                </div>
                <div className="subtitle">
                  {e.time} | {e.who}
                </div>
              </>
            ) : (
              <div className="title">
                {formatDateDayMonth(e.date)} - 🥳 {e.name} 🎉
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

function combineEventsAndBirthdays(
  events: CalendarEvent[],
  birthdays: Birthday[],
): (CalendarEvent | Birthday)[] {
  const all = [...events, ...birthdays];
  all.sort((a, b) => {
    if (a.date && b.date) {
      return a.date.localeCompare(b.date);
    }
    if (!a.date && !b.date) {
      return 0;
    }
    return a.date ? -1 : 1;
  });
  return all;
}

function isCalendarEvent(
  item: CalendarEvent | Birthday,
): item is CalendarEvent {
  return 'description' in item;
}
