import { TokenApiService } from './TokenApiService';
import { Injector } from 'react-service-injector';

export interface Photo {
  title: string;
  publicUrl: string;
}

export interface Gallery {
  title: string;
  photos: Photo[];
}

export class GalleryService {
  private readonly api: TokenApiService;

  public constructor(injector: Injector) {
    this.api = injector.resolve(TokenApiService);
  }

  public getGalleries(): Promise<Gallery[]> {
    return this.api.jsonGet('/gallery');
  }
}
