export function randomElement<T>(list: T[]): T {
  return list[randomIntMax(list.length)];
}

export function randomIntMax(maxExcluding: number): number {
  return Math.floor(maxExcluding * Math.random());
}

export function randomIntBetween(min: number, max: number): number {
  const absMin = Math.abs(min);
  const total = max + absMin + 1;
  return randomIntMax(total) - absMin;
}
