import React from 'react';
import ReactDOM from 'react-dom/client';
import { Injector, InjectorProvider } from 'react-service-injector';
import './index.scss';
import { GalleryPage } from './pages/GalleryPage';

const injector = new Injector();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <InjectorProvider value={injector}>
      <GalleryPage />
    </InjectorProvider>
  </React.StrictMode>,
);
