export type Params = Record<string, string | number | undefined>;

export class TokenApiService {
  private readonly token: string;
  private readonly defaultHeaders: Record<string, string>;

  public constructor() {
    this.token = new URLSearchParams(window.location.search).get('token') || '';
    this.defaultHeaders = {
      Accept: 'application/json',
    };
  }

  private static async handleResponse<T>(
    resPromise: Promise<Response>,
  ): Promise<T> {
    const response = await resPromise;
    const responseBody =
      response.status === 204 ? undefined : await response.json();

    if (response.status >= 400) {
      throw responseBody;
    }
    return responseBody;
  }

  private getUrl(path: string, params?: Params): string {
    const stringParams: Record<string, string> = {};
    if (params) {
      Object.entries(params).forEach(([k, v]) => {
        if (v != null) {
          stringParams[k] = `${v}`;
        }
      });
    }
    stringParams.token = this.token;

    return `/api${path}?${new URLSearchParams(stringParams)}`;
  }

  private jsonRequest<T>(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    path: string,
    body?: unknown,
    params?: Params,
  ): Promise<T> {
    const headers = this.defaultHeaders;
    if (body) {
      headers['Content-Type'] = 'application/json';
    }

    return TokenApiService.handleResponse(
      fetch(this.getUrl(path, params), {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      }),
    );
  }

  public jsonGet<T>(path: string, params?: Params): Promise<T> {
    return this.jsonRequest('GET', path, undefined, params);
  }
}
